import React, { useRef, useEffect, useState } from "react"
import { Toaster } from "react-hot-toast"
import Fade from "react-reveal/Fade"
import Layout from "../components/Layout"
import Heading from "../components/Heading"
import WorkSteps from "../components/WorkSteps"
import Industries from "../components/Industries"
import Advantages from "../components/Advantages"
import WhyCustom from "../components/WhyCustom"
import SEO from "../components/seo"
import Loader from "../components/Loader"
import "../styles/pages-style/main.scss"

const Main = () => {
	const advantagesBlock = useRef(null)
	const [offsetAdvantages, setOffsetAdvantages] = useState(0)
	const [bgChangeAdvantages, setBgChangeAdvantages] = useState(false)

	const industriesBlock = useRef(null)
	const [offsetIndustries, setOffsetIndustries] = useState(0)
	const [bgChangeIndustries, setBgChangeIndustries] = useState(false)

	const whyCustomBlock = useRef(null)
	const [offsetWhyCustom, setOffsetWhyCustom] = useState(0)
	const [bgChangeWhyCustom, setBgChangeWhyCustom] = useState(false)

	const workStepsBlock = useRef(null)
	const [offsetWorkSteps, setOffsetWorkSteps] = useState(0)
	const [bgChangeWorkSteps, setBgChangeWorkSteps] = useState(false)

	const handleScroll = (e) => {
		let offsetTop = window.pageYOffset
		let windowHeight = window.innerHeight

		offsetTop + (windowHeight / 2) > offsetAdvantages ? setBgChangeAdvantages(true) : setBgChangeAdvantages(false)
		offsetTop + (windowHeight / 2) > offsetIndustries ? setBgChangeIndustries(true) : setBgChangeIndustries(false)
		offsetTop + (windowHeight / 2) > offsetWhyCustom ? setBgChangeWhyCustom(true) : setBgChangeWhyCustom(false)
		offsetTop + (windowHeight / 2) > offsetWorkSteps ? setBgChangeWorkSteps(true) : setBgChangeWorkSteps(false)
	}

	useEffect(() => {
		let bodyRect = document.body.getBoundingClientRect()

		let advantagesRect = advantagesBlock.current.getBoundingClientRect()
		let advantagesPos = advantagesRect.top - bodyRect.top
		setOffsetAdvantages(advantagesPos)

		let industriesRect = industriesBlock.current.getBoundingClientRect()
		let industriesPos = industriesRect.top - bodyRect.top
		setOffsetIndustries(industriesPos)
		
		let whyCustomRect = whyCustomBlock.current.getBoundingClientRect()
		let whyCustomPos = whyCustomRect.top - bodyRect.top
		setOffsetWhyCustom(whyCustomPos)

		let workStepsRect = workStepsBlock.current.getBoundingClientRect()
		let workStepsPos = workStepsRect.top - bodyRect.top
		setOffsetWorkSteps(workStepsPos)

		window.addEventListener('scroll', handleScroll)

		return () => {
			window.removeEventListener('scroll', handleScroll)
		}
		
	}, [offsetAdvantages, offsetIndustries, offsetWhyCustom, handleScroll])

	const [animate, setAnimate] = useState(true)

	function changeLoad() {
		setTimeout(() => {
			setAnimate(false)
		}, 500)
	}

	useEffect(() => {
		window.addEventListener('load', changeLoad(), false)
	}, [])

	return (
		<div>
			{!animate ? null : <Loader />}
			<Layout>
				<SEO title="Services | Cider Consulting" />
				<div>
					<Heading
						caption="Custom software, headache-free."
						text="We streamline your operations through custom software."
						bottomContent={
							<div className="reduce-list">
								<div className="reduce-list__wrap">
									<Fade bottom duration={1000}>
										<span className="reduce-list__title">Our process allows us to reduce:</span>
									</Fade>
									<ol className="number-list reduce-list__box">
										<Fade bottom duration={1000}>
											<li>Your time invested</li>
										</Fade>
										<Fade bottom duration={1000} delay={150}>
											<li>Uncertainty of outcomes</li>
										</Fade>
									</ol>
								</div>
							</div>
						}
					/>
				</div>

				<div ref={workStepsBlock} className={bgChangeWorkSteps ? 'change-bg-work-steps' : ''}>
					<WorkSteps />
				</div>

				<div ref={industriesBlock} className={bgChangeIndustries ? 'change-bg-industries' : ''}>
					<Industries />
				</div>

				<div ref={advantagesBlock} className={bgChangeAdvantages ? 'change-bg-advantages' : ''}>
					<Advantages bgChange={bgChangeAdvantages} />
				</div>
				
				<div ref={whyCustomBlock} className={bgChangeWhyCustom ? 'change-bg-why-custom' : ''}>
					<WhyCustom bgChange={bgChangeWhyCustom} />
				</div>
				<Toaster/>
			</Layout>
		</div>
	)
}

export default Main
