import React from "react"
import Header from "../Header"
import Footer from "../Footer"
import "../../styles/styles.css"
import "../../styles/pageload.css"
import './style.scss'

const Layout = ({ children, animating }) => {

	return (
		<div className="layout">
			<div className="header-wrap">
				<Header />
			</div>
			<main>{children}</main>
			<Footer
				link1="sales@cider.consulting"
				link2="/"
				link3="/"
				button1="sales@cider.consulting"
				button2="Schedule a meeting"
				button3="Call (845) 262-0426‬"
			/>
		</div>
	)
}

export default Layout
