import React from "react"
import Fade from "react-reveal/Fade"
import advantages1 from "../../images/advantages/1.svg"
import advantages2 from "../../images/advantages/2.svg"
import advantages3 from "../../images/advantages/3.svg"
import advantages1_white from "../../images/advantages/1-white.svg"
import advantages2_white from "../../images/advantages/2-white.svg"
import advantages3_white from "../../images/advantages/3-white.svg"
import "./style.scss"

const AdvantagesItem = ({ caption, content, img, reverse }) => {

    return (
        <div className="advantages-item">
            <Fade bottom duration={1000}>
                <span className="caption advantages-item__caption">{caption}</span>
            </Fade>
            <div className={`advantages-item__row ${reverse && "advantages-item__row--reverse"}`}>
                <Fade bottom duration={1000}>
                    <div className="advantages-item__text">{content}</div>
                </Fade>
                <Fade bottom duration={1000}>
                    <img src={img} alt="" className="advantages-item__img"/>
                </Fade>
            </div>
        </div>
    )
}

const Advantages = ({ bgChange }) => {

	return (
		<div className="advantages">
            <div className="container">
                <AdvantagesItem
                    caption="We’re good listeners."
                    content={
                        <>
                            <p>Our favorite part of the process is sitting down with you and learning all the nitty-gritty details of your business.</p>
                            <p>Our discovery process is thorough but efficient: we take one day of your time to get a full picture of your needs, then we dive right in.</p>
                        </>
                    }
                    img={!bgChange ? advantages1_white : advantages1}
                />
                <AdvantagesItem
                    caption="Cutting-edge software. Old fashioned values."
                    content={
                        <>
                            <p>It’s as simple as delivering on time, on budget, and as advertised. With Cider, you’re a part of the process, and we keep you informed at every turn.</p>
                        </>
                    }
                    img={!bgChange ? advantages2_white : advantages2}
                    reverse
                />
                <AdvantagesItem
                    caption="Human interaction is our business."
                    content={
                        <>
                            <p>We design human-first custom code that feels natural to use. In our user journeys, your employees are the heroes. They won’t stop thanking you.</p>
                        </>
                    }
                    img={!bgChange ? advantages3_white : advantages3}
                />
            </div>
		</div>
	)
}

export default Advantages
