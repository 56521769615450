import React from "react"
import Fade from "react-reveal/Fade"
import "./style.scss"

const WorkSteps = () => {

	return (
		<div className="work-steps">
            <div className="container">
                <Fade bottom duration={1000}>
                    <span className="caption work-steps__caption">We’ve never failed at a digital transformation. Never. Not once.</span>
                </Fade>
                <div className="work-steps__wrap">
                    <Fade bottom duration={1000}>
                        <span className="title work-steps__title">How have we done this?</span>
                    </Fade>
                    <ol className="number-list work-steps__list">
                        <Fade bottom duration={1000}>
                            <li>By listening</li>
                        </Fade>
                        <Fade bottom duration={1000}>
                            <li>By providing as close to up-front pricing as possible</li>
                        </Fade>
                        <Fade bottom duration={1000}>
                            <li>By reducing time-to-launch</li>
                        </Fade>
                        <Fade bottom duration={1000}>
                            <li>By allowing you to review the interface before it’s designed</li>
                        </Fade>
                        <Fade bottom duration={1000}>
                            <li>By producing independently functional modules</li>
                        </Fade>
                        <Fade bottom duration={1000}>
                            <li>By providing full transparency and visibility on progress to date</li>
                        </Fade>
                        <Fade bottom duration={1000}>
                            <li>By requiring as little time from you and your management team as possible</li>
                        </Fade>
                    </ol>
                    <div className="work-steps__bottom">
                        <Fade bottom duration={1000}>
                            <span className="title work-steps__title">Our promise:</span>
                        </Fade>
                        <Fade bottom duration={1000}>
                            <p className="title work-steps__text">To build intuitive user-interfaces your employees will love</p>
                        </Fade>
                    </div>
                </div>
            </div>
		</div>
	)
}

export default WorkSteps
