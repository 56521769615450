import React from "react"
import "../styles/bubbles.css"

const Bubbles = ({  }) => {
  return (
    <div className="bubbleContainer overflow-hidden">
      <div className="bubble1 bg-yellow md:h-40 md:w-40"></div>
      <div className="bubble2 bg-yellow lg:h-30 lg:w-30"></div>
      <div className="bubble3 bg-yellow md:h-30 md:w-30"></div>
      <div className="bubble4 bg-yellow md:h-20 md:w-20"></div>
    </div>
  )
}

export default Bubbles
