import React from "react"
import Fade from "react-reveal/Fade"
import advantages4 from "../../images/advantages/4.svg"
import advantages4_white from "../../images/advantages/4-white.svg"
import "./style.scss"

const WhyCustom = ({ bgChange }) => {

	return (
		<div className="why-custom" id="why-custom">
            <div className="container">
                <Fade bottom duration={1000}>
                    <span className="caption why-custom__caption">Why Custom?</span>
                </Fade>
                <div className="why-custom__row">
                    <div className="why-custom__text">
                        <Fade bottom duration={1000}>
                            <span className="title why-custom__title">Our work is meant to go unnoticed.</span>
                        </Fade>
                        <Fade bottom duration={1000}>
                            <img src={advantages4} alt="" className="why-custom__img why-custom__img--mobile" />
                        </Fade>
                        <Fade bottom duration={1000}>
                            <p>Shopping can be stressful. You go from store to store, wanting nothing more than to buy the suit jacket you need.</p>
                        </Fade>
                        <Fade bottom duration={1000}>
                            <p>After a while of searching, you settle. You walk out with something that fits “OK”.</p>
                        </Fade>
                        <Fade bottom duration={1000}>
                            <p>So, you try to actually wear the “OK” jacket. Only then do you really notice how it just feels off.</p>
                        </Fade>
                        <Fade bottom duration={1000}>
                            <p>The little annoyances add up throughout the day, nagging you and adding to your psychic burden.</p>
                        </Fade>
                        <Fade bottom duration={1000}>
                            <span className="title why-custom__descr">You won’t have that problem with us.</span>
                        </Fade>
                    </div>
                    <Fade bottom duration={1000}>
                        <img src={bgChange ? advantages4 : advantages4_white} alt="" className="why-custom__img" />
                    </Fade>
                </div>
            </div>
		</div>
	)
}

export default WhyCustom
