import React from "react"
import Fade from "react-reveal/Fade"
import Bubbles from "../../components/Bubbles"
import "./style.scss"

const Heading = ({ caption, text, img, bottomContent, bgImageClassName }) => {

	return (
		<>
			<div className="heading-hero" id="heading-hero">
				<Bubbles />
				<div className="container">
					<div className="heading-hero__wrap">
						<div className="heading-hero__info">
							<Fade bottom duration={1000}>
								<h1 className="heading-hero__caption">{caption}</h1>
							</Fade>
							<Fade bottom duration={1000} delay={100}>
								<p className="caption heading-hero__text">{text}</p>
							</Fade>
						</div>
						{img ? (
							<div className={`heading-hero__img ${bgImageClassName}`}>
								{bgImageClassName ? <div style={{ backgroundImage: `url(${img})` }} /> : null}
								<img src={img} alt="" />
							</div>
						) : null}

					</div>
					{bottomContent ? (
						<div className="heading-hero__bottom">
							{bottomContent}
						</div>
					) : null}
				</div>
			</div>
		</>
	)
}

export default Heading
