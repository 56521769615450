import React from "react"
import Fade from "react-reveal/Fade"
import industries1 from "../../images/industries/1.png"
import industries2 from "../../images/industries/2.png"
import industries3 from "../../images/industries/3.png"
import industries4 from "../../images/industries/4.png"
import industries5 from "../../images/industries/5.png"
import "./style.scss"

const industriesList = [
    { img: industries1, text: "Manufacturing" },
    { img: industries2, text: "Pharmaceutical" },
    { img: industries3, text: "Health Care" },
    { img: industries4, text: "Banking / Finance" },
    { img: industries5, text: "e-Commerce" },
]

const IndustriesItem = ({ img, text }) => {

    return (
        <div className="industries-item">
            <img src={img} alt="" />
            <span className="title">{text}</span>
        </div>
    )
}

const Industries = () => {

	return (
		<div className="industries" id="industries">
            <div className="container">
                <Fade bottom duration={1000}>
                    <span className="caption industries__caption">Industries we<span>’</span>ve conquered:</span>
                </Fade>
                <div className="industries__wrap">
                    {industriesList.map((item, index) => {
                        return (
                            <Fade bottom duration={1000} delay={index % 2 === 0 ? 0 : 200} key={`industries-${index}`}>
                                <div className="industries__col">
                                    <IndustriesItem img={item.img} text={item.text} />
                                </div>
                            </Fade>
                        )
                    })}
                </div>
            </div>
		</div>
	)
}

export default Industries
